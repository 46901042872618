@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
*
{
    margin: 0;
    padding: 0;
}

html,
body
{
    overflow: hidden;
    font-family: 'Poppins', sans-serif;
}

.webgl
{
    position: fixed;
    top: 0;
    left: 0;
    outline: none;
}

.loading-bar
{
    position: absolute;
    top: 50%;
    width: 100%;
    height: 2px;
    background: #fff;
    transform: scaleX(0);
    transform-origin: top left;
    transition: transform 0.5s;
    will-change: transform;
}
.loading-bar.ended
{
    /*transform: scaleX(0);*/
    transform-origin: top right;
    transition: transform 1.5s ease-in-out;
}

.loading-logo{
    position: absolute;    
    top: 27.25%;
    left: 50%;    
    transform: translate(-50%, -50%);
    transition: opacity 0.5s;
    transition: visibility 0.6s;
}
.loading-logo.ended{
    opacity: 0;    
    visibility: hidden;
}

.number-progress{
    position: absolute;
    width: 601px;
    height: 274px;
    left: 50%;
    top: 60%;
    transform: translate(-50%, -50%);
    font-style: normal;
    font-weight: 250;
    font-size: 200px;
    line-height: 300px;
    text-align: center;
    color: #FFFFFF;
    transition: opacity 0.5s;
    transition: visibility 0.6s;
}
.number-progress.ended{
    opacity: 0;
    visibility: hidden;
}

.poweredBy.ended{
    opacity: 0;
    visibility: hidden;
}

.menu{
    position: absolute;
    width: 250px;
    height: 79px;
    left: 20px;
    top: 20px;
    background: #FFFFFF;
    border-radius: 20px;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.25);
    transform: translateX(-400px);
    overflow: hidden;
}
.header-menu{
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}
.logo-epi{
    margin: 16px 0px 0px 23px;
}
.drop_down{
    margin: 13.72px 30px 0px 0px;
}
.header-menu .drop_down img {
    rotate: 180deg;
    transition: all 300ms linear;
}
.header-menu.close .drop_down img{
    rotate: 0deg;
}
.label-vip, .label-plata{
    color:#FAB8C4;
    margin: 19px 0px 0px 23px;
    font-weight: 600;
    font-size: 10px;
    line-height: 15px;
}
.label-plata{
    margin: 27px 0px 0px 23px;
}
.vip{
    margin: 6px 0px 0px 17px;
    width: 216px;
    border-radius: 15px;
    display: flex;
    align-items: center;
    cursor: pointer;
    /*transition: background 0.15s;*/
}
.other-vip{
    margin-top: 0px;
}
.vip:hover{
    background: #E6E6FC
}
.vip:hover .name-vip{
    color:#5956E9;
} 
.vip img{
    margin: 5px 0px 5px 5px;
}
.name-vip{
    font-weight: 700;
    font-style: normal;
    font-size: 12px;
    line-height: 18px;
    margin-left: 9px;
    color: #1D001E;
    /*transition: background 0.15s;*/
}
.class-expo{
    color: #5956E9;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 15px;
    margin-left: 9px;
    margin-top: -5px;
}
.section-plata{
    display: flex;
    width: 206px;
    margin-left: 22px;
}
.plata{
    display: flex;
    align-items: center;
    width: 100px;
    border-radius: 10px;
    margin: 6px 6px 6px 0px;
    cursor: pointer;
}

.plata:hover{
    background: #E6E6FC;
}
.plata:hover .name-plata{
    color: #5956E9;
}
.plata-two{
    margin-top: 0px !important;
}
.bar{
    background: #5956E9;
    border: 1px solid #5956E9;
    width: 0px;
    height: 23px;
    margin: 6px 5px 6px 10px;
}
.name-plata{
    font-style: normal;
    font-weight: 700;
    font-size: 11px;
    line-height: 15px;
    color:#1D001E;
}
.class-plata{
    font-style: normal;
    font-weight: 500;
    font-size: 9px;
    line-height: 12px;
    color: #5956E9;
}

.menu-stand{
    position: absolute;
    top: 20px;
    left: 20px;    
    width: 250px;
    height: 256px;
    background-color: #5956E9;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    transform: translateX(-400px);
}
.header_menu_stand{
    display: flex;
    justify-content: space-between;
    height: 62px;
}
.section-dropdown{
    overflow: hidden;
    opacity: 0;
    pointer-events: none;
    transform: translateY(-40px);
    transition: all 200ms linear;
}
.section-dropdown.open{
    opacity: 1;
    pointer-events: auto;
    transform: translateY(0);   
}
.section_stand_name{
    margin: 14px 0px 25px 24px;
}
.stand_menu_name{
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
    color: #FFFFFFFF;
}
.stand_menu_class{
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 15px;
    color: #FAB8C4;
    margin-top: -4px;
}
.close_stand{
    width: 30px;
    height: 30px;
    background: #FAB8C4;
    border-radius: 50%;
    display: flex;    
    align-items: center;
    justify-content: center;
    margin: 11px 12px 0px 0px;
}
.close_stand:hover{
    background: #FFDC60;
}
.close_stand:hover svg path{
    fill: #5956E9;
}
.section_logo{
    margin-left: 12px;
    margin-bottom: 12px;
    width: 226px;
    height: 130px;
}
.section_icons{
    display: flex;
}
.icon_stand{
    width: 40px;
    height: 40px;
    background: #FAB8C4;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px 6px;
    cursor: pointer;
}
.icon_stand:first-child{
    margin-left: 12px;
}
.icon_stand:hover{
    background: #FFDC60;
}
.icon_stand:hover svg path{
    fill: #5956E9;
}
.poweredBy{
    position: absolute;
    bottom: 17px;
    right: 21px;
    transition: opacity 0.5s;
    transition: visibility 0.6s;
}

.point
{
    position: absolute;
    top: 50%;
    left: 50%;
}

.point .label
{
    position: absolute;
    top: -20px;
    left: -20px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #00000077;
    border: 1px solid #ffffff77;
    color: #fff;
    font-family: Arial, Helvetica, sans-serif;
    text-align: center;
    cursor: pointer;
    line-height: 40px;
    font-weight: 100;
    font-size: 14px;
    transform: scale(0, 0);
    transition: transform 0.3s;
}

.point.visible .label
{
    transform: scale(1, 1);
}

.point:hover .text
{
    opacity: 1;
}

.point .text
{
    position: absolute;
    top: 30px;
    left: -120px;
    width: 200px;
    padding: 20px;
    border-radius: 4px;
    background: #00000077;
    border: 1px solid #ffffff77;
    color: #fff;
    line-height: 1.3em;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 100;
    font-size: 14px;
    opacity: 0;
    transition: opacity 0.3s;
    pointer-events: none;
}

.howToUse-desktop, .howToUse-movile{
    position: absolute;
    bottom: 0;
    left: 0;    
    width: 200px;
    background-color: #ffffff80;
    opacity: 0;
    transition: opacity 0.05s;
    will-change: opacity;
}

.notWork{
    position: absolute;
    opacity: 0;  
}
.logo-notWork{
    margin: 84px 50px 45px 47px;
}
.content-notwork{
    width: 100%;
    display: flex;
    align-items: center;
}
.section-notwork{
    display: flex;
    flex-direction: column;
    width: 302px;
    margin-left: 39px;
    background: #FFFFFF;
    border-radius: 20px;
}
.danger-notwork{
    margin: 38px 108px;
}
.text-notwork{
    margin: 0px 28px;
}
.text-notwork p{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #999FAE;
}
.text-notwork p:first-child{
    margin-bottom: 25px;
}
.link-notwork{
    width: 240px;
    height: 72px;
    margin: 30px 31px 24px 31px;
    text-decoration: none;    
}
.button-notwork{
    width: 240px;
    height: 72px;
    background: #5956E9;
    border-radius: 20px;
    /*margin: 30px 31px 24px 31px;*/
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
}
.pop-up{
    position: absolute;
    top: 50%;
    left: 50%;
}
.pop-up .content{
    transform: scale(0, 0);
    transition: transform 0.3s;
}

.pop-up.visible .content{
    transform: scale(1, 1);
}

@media (max-width: 1024px) {
    .number-progress{
        font-size: 160px;
        top: 63%;
    }
    .loading-logo{
        width: 312px;
        height: 80px;
    }
    .loading-logo svg{        
        width: 312px;
        height: 80px;
    }
}